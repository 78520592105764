<template>
    <div>
        <h2>Welcome</h2>
        <p class="d-none">
            Don't have an account?
            <b-link to="signup">Sign up</b-link>
        </p>
        <p>Please log in to access ontologies</p>
        <b-form @submit.prevent="onSubmit" novalidate>
            <template v-if="islocalAuth">
                <b-form-group>
                    <label
                        for="username-field"
                        class="control-label text-primary"
                        >Username</label
                    >

                    <span
                        v-show="isInvalidDirty('username')"
                        class="invalid-feedback"
                        >Please enter a valid username</span
                    >
                    <b-form-input
                        @input="validate('username', $event)"
                        id="username-field"
                        type="text"
                        :autofocus="true"
                        rules="required"
                        autocapitalize="none"
                        autocorrect="off"
                        spellcheck="false"
                        autocomplete="username"
                        v-model="form.username"
                        name="username">
                    </b-form-input>
                </b-form-group>

                <b-form-group>
                    <label
                        for="password-field"
                        class="control-label text-primary"
                        >Password</label
                    >
                    <span
                        v-show="isInvalidDirty('password')"
                        class="invalid-feedback"
                        >Please enter a valid password</span
                    >

                    <b-form-input
                        @input="validate($event, 'password')"
                        id="password-field"
                        type="password"
                        autocapitalize="none"
                        autocorrect="off"
                        spellcheck="false"
                        autocomplete="current-password"
                        v-model="form.password"
                        name="password">
                    </b-form-input>
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox v-model="form.isRemember">
                        Stay logged in
                        <info-icon
                            help-hint="Keeps you logged in across tabs and after closing the browser." />
                    </b-form-checkbox>
                    <b-link to="reset" class="float-right d-none"
                        >Forgot password?</b-link
                    >
                    <b-button
                        class="login-btn w-100"
                        type="submit"
                        variant="primary"
                        :disabled="loading">
                        <span v-show="!loading">Log in</span>
                        <span class="loading" v-show="loading"></span>
                    </b-button>
                </b-form-group>
            </template>

            <b-form-group v-show="redirectURL">
                <b-button
                    class="login-btn w-100"
                    variant="primary"
                    v-on:click="redirectOpenID"
                    :disabled="loading">
                    <span v-show="!loading">Log in with OpenID</span>
                    <span class="loading" v-show="loading"></span>
                </b-button>
            </b-form-group>
        </b-form>
    </div>
</template>

<script>
import { isEmpty, pick } from 'lodash';
import InfoIcon from '@/components/ui/InfoIcon';
import ApiAuth from '@/api/auth';
import ApiManagement from '@/api/management';
import { HELP_HINTS } from '@/config';

export default {
    name: 'Login',
    components: { InfoIcon },

    props: {
        usrRegex: {
            type: RegExp,
            default() {
                return /^[_.@A-Za-z0-9-]*$/;
            },
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        helpHints: {
            type: Object,
            default() {
                return HELP_HINTS;
            },
        },
    },

    data() {
        return {
            loading: false,
            redirectURL: null,
            islocalAuth: false,
            loadingLocalAuthData: true,
            valid: true,
            invalid: {
                username: false,
                password: false,
            },
            dirty: {
                username: false,
                password: false,
            },
            form: {
                username: '',
                password: '',
                isRemember: false,
            },
        };
    },

    computed: {
        hasErrors: function () {
            return Object.values(this.invalid).includes(true);
        },
    },

    beforeMount() {
        ApiAuth.getRedirectURL().then((redirect) => {
            this.redirectURL = redirect.data.url;
        });
    },

    created() {
        ApiManagement.getManagementData()
            .then((managementData) => {
                const localAuthDisabled =
                    managementData.data['local-authentication-disabled'];
                console.log('Local Auth Disabled', localAuthDisabled);
                if (localAuthDisabled) {
                    if (typeof localAuthDisabled === 'string') {
                        this.islocalAuth = !(localAuthDisabled === 'true');
                    } else {
                        this.islocalAuth = !localAuthDisabled;
                    }
                }
                console.log('this.isLocalAuth = ', this.islocalAuth);
            })
            .finally(() => {
                this.loadingLocalAuthData = false;
            });
    },

    methods: {
        validate(fieldName, value) {
            if (fieldName === 'isRemember') return;
            this.dirty[fieldName] = true;
            this.invalid[fieldName] = isEmpty(value);

            if (!this.invalid[fieldName] && fieldName === 'username')
                this.invalid[fieldName] = !value.match(this.usrRegex);
        },
        redirectOpenID() {
            this.loading = true;
            window.open(this.redirectURL, '_self');
        },
        /**
         * Flags a given field if it has an error and has been changed at least once.
         * @param {string} fieldName - Value of the HTML "name" attribute of the field in question.
         */
        isInvalidDirty(fieldName) {
            return this.invalid[fieldName] && this.dirty[fieldName];
        },

        validateForm() {
            return new Promise((resolve) => {
                Object.entries(this.form).forEach(([fieldName, value]) => {
                    this.validate(fieldName, value);
                });

                return resolve();
            });
        },

        /**
         * Performs bulk validation and, if passed, it updates the app's state
         */
        async onSubmit() {
            let redirectRoute = pick(this.$route.params.redirect, [
                'name',
                'params',
            ]);

            if (this.loadingLocalAuthData) {
                this.$eventBus.$emit(
                    'notification:show',
                    'warning',
                    'CENtree cannot identify the current login configuration.',
                    'Unable to log in'
                );
                return;
            }

            if (!this.islocalAuth) {
                this.$eventBus.$emit(
                    'notification:show',
                    'warning',
                    'CENtree is currently configured to only allow OpenID as the login method.',
                    'Unable to log in'
                );
                return;
            }

            await this.validateForm();

            if (this.hasErrors) return;

            this.loading = true;
            this.$store.isPreventGlobalError = true;

            // Redirects to original page requested.
            this.$store
                .dispatch('login', this.form)
                .then(() => {
                    if (isEmpty(redirectRoute)) {
                        this.$router.push('/');
                    } else {
                        this.$router.push(redirectRoute);
                    }
                    localStorage.setItem(
                        'rememberMe',
                        JSON.stringify(this.form.isRemember)
                    );
                })
                .catch((error) => {
                    // Recovers specifically from wrong login credentials
                    if (error.response && error.response.status === 403) {
                        this.$eventBus.$emit(
                            'notification:show',
                            'warning',
                            `Please check your username and password and try again.`,
                            `Unable to log in`
                        );

                        // Error was of a different nature => falls back on global error broker.
                    } else {
                        this.$store.isPreventGlobalError = false;
                        this.$api.onError(error);
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.isPreventGlobalError = false;
                });
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.form-control {
    padding-right: 2rem;
}
.form-group:nth-child(2) {
    margin-bottom: 1.25rem; // Compensates for the labels' line height
}

.loading {
    position: relative;
    display: inline-block;
    font-size: 1em;

    &:after {
        content: 'Logging in...';
        font-weight: inherit;
        color: inherit;
    }
}
</style>
